import { Field } from "formik"
import React from "react"

const SelectField = ({ name, options, label }) => {
  return (
    <label className="block text-gray-700" htmlFor={name}>
      <span>{label}</span>
      <Field
        className="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        as="select"
        name={name}
      >
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Field>
    </label>
  )
}

export default SelectField
