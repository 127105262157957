import React from "react"
import { Field, ErrorMessage } from "formik"

const InputText = ({ type = "text", name = "", label = "" }) => {
  return (
    <label className="block text-gray-700" htmlFor={name}>
      <span className="block">{label}</span>
      <Field
        type={type}
        name={name}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
      <ErrorMessage className="text-red-500" name={name} component="div" />
    </label>
  )
}

export default InputText
