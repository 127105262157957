/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createHelp = /* GraphQL */ `
  mutation CreateHelp(
    $input: CreateHelpInput!
    $condition: ModelHelpConditionInput
  ) {
    createHelp(input: $input, condition: $condition) {
      id
      name
      email
      question
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateHelp = /* GraphQL */ `
  mutation UpdateHelp(
    $input: UpdateHelpInput!
    $condition: ModelHelpConditionInput
  ) {
    updateHelp(input: $input, condition: $condition) {
      id
      name
      email
      question
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteHelp = /* GraphQL */ `
  mutation DeleteHelp(
    $input: DeleteHelpInput!
    $condition: ModelHelpConditionInput
  ) {
    deleteHelp(input: $input, condition: $condition) {
      id
      name
      email
      question
      description
      createdAt
      updatedAt
    }
  }
`;
