import React from "react"

const SuccessBox = () => {
  return (
    <div className="w-full  mx-auto">
      <div className="flex flex-col p-5 rounded-lg shadow bg-white">
        <div className="flex flex-col items-center text-center">
          <div className="inline-block p-4 bg-yellow-50 rounded-full">
            <div className="text-green-500">
              <svg
                className="w-6 sm:w-5 h-6 sm:h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
            </div>
          </div>
          <h2 className="mt-2 text-xl font-semibold text-green-500">Success</h2>
          <p className="mt-2 text-2xl text-gray-600 leading-relaxed">
            Thanks for filling out our form!
          </p>
        </div>
      </div>
    </div>
  )
}

export default SuccessBox
