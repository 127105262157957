import React, { useState } from "react"
import Layout from "../components/layout"
import InputText from "../components/inputText"
import { Form, Formik, Field, ErrorMessage } from "formik"
import SelectField from "../components/selectField"
import SuccessBox from "../components/SuccessBox"
import { createHelp } from "../graphql/mutations"
import * as Yup from "yup"
import { Amplify, API, graphqlOperation } from "aws-amplify"
import config from "../aws-exports"
import Seo from "../components/seo"
Amplify.configure(config)

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  question: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
})

const ContactUsPage = () => {
  const [successMsg, setSuccessMsg] = useState(false)
  return (
    <Layout title="Contact Us">
      <Seo
        description="Do you have a question about the PennVet Behavior App website or need help with your dog's evaluation? Use the form below to get in touch with us."
        title="Contact us"
      />
      <div className="md:container md:mx-auto pb-16 pt-16">
        <div className="contact-us-form max-w-xl mx-auto">
          {successMsg && <SuccessBox />}
          {!successMsg && (
            <Formik
              initialValues={{
                name: "",
                email: "",
                question:
                  "I have a general question about the PennVet Behavior App website.",
                description: "",
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                const helpData = {
                  name: values.name,
                  email: values.email,
                  question: values.question,
                  description: values.description,
                }
                try {
                  await API.graphql(
                    graphqlOperation(createHelp, { input: helpData })
                  )
                  setSuccessMsg(true)
                } catch (e) {
                  console.log("Error", e)
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col gap-4">
                  <div className="block">
                    <h1 className="text-3xl mb-4">Help</h1>
                    <span>
                      Do you have a question about the PennVet Behavior App
                      website or need help with your dog's evaluation? Use the
                      form below to get in touch with us.
                    </span>
                  </div>
                  <InputText label="Name" name="name" type="text" />

                  <InputText label="E-mail address" name="email" type="email" />

                  <SelectField
                    label="How can we help you?"
                    name="question"
                    id="question"
                    options={[
                      {
                        value:
                          "I have a general question about the PennVet Behavior App website.",
                        label:
                          "I have a general question about the PennVet Behavior App website.",
                      },
                      {
                        value:
                          "I'd like to better understand the results my dog received.",
                        label:
                          "I'd like to better understand the results my dog received.",
                      },
                      {
                        value:
                          "I'm having a problem using the PennVet Behavior App website or have encountered an error.",
                        label:
                          "I'm having a problem using the PennVet Behavior App website or have encountered an error.",
                      },
                      {
                        value:
                          "I'd like to know how my group, practice, or study can participate in the PennVet Behavior App.",
                        label:
                          "I'd like to know how my group, practice, or study can participate in the PennVet Behavior App.",
                      },
                      {
                        value:
                          "I'd like to learn more about how the PennVet Behavior App was developed and the research behind it.",
                        label:
                          "I'd like to learn more about how the PennVet Behavior App was developed and the research behind it.",
                      },
                    ]}
                  />

                  <label className="block text-gray-700" htmlFor="description">
                    <span>
                      Briefly provide more detail about your question.
                    </span>
                    <span>
                      For technical issues, please tell us what you're trying to
                      accomplish and the text of any error messages that have
                      been displayed.
                    </span>
                    {/* <InputText label="Name" name="description" type="text" /> */}
                    {/* <TextArea rows={6} name="reasonDetails" /> */}
                    {/* <Field
                    rows={6}
                    name="reasonDetails"
                    type="textarea"
                    as="textarea"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  /> */}
                    <Field
                      rows={6}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      as="textarea"
                      id="description"
                      name="description"
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="text-red-500"
                    />
                  </label>
                  <button
                    className="flex rounded-md  shadow-sm bg-[#73153d] justify-center items-center border-2 border-[#73153d] px-5 py-2 text-white gap-4 text-xl min-w-[236px]"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default ContactUsPage
